var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("editor", {
        ref: "tm",
        attrs: { id: _vm.id, init: _vm.init },
        on: { onInit: _vm.tinyloaded },
        model: {
          value: _vm.tinyeditor,
          callback: function ($$v) {
            _vm.tinyeditor = $$v
          },
          expression: "tinyeditor",
        },
      }),
      _vm._m(0),
      _c("tinycom", {
        attrs: {
          id: "tiny_com",
          "called-by": _vm.tiny_calledby,
          operation: _vm.tiny_operation,
          hidden: "",
        },
        on: { click: _vm.emitEvent },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: { id: "tinyMocal", role: "dialog", tabindex: "-1" },
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog modal-dialog-centered",
            attrs: { role: "document" },
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v("Selector de color"),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      id: "close-color-selector",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v(" Selecciona el color "),
                _c("input", {
                  attrs: { type: "color", id: "selector-de-color" },
                }),
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      id: "cancel-s-color",
                    },
                  },
                  [_vm._v(" Cancelar ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", id: "select-color" },
                  },
                  [_vm._v(" Confirmar ")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }