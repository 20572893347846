<template>
  <div>
    <editor
      :id="id"
      :init="init"
      v-model="tinyeditor"
      v-on:onInit="tinyloaded"
      ref="tm"
    />

    <div id="tinyMocal" class="modal" role="dialog" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered " role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Selector de color</h5>
            <button
              type="button"
              id="close-color-selector"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Selecciona el color
            <input type="color" id="selector-de-color" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              id="cancel-s-color"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" id="select-color">
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </div>
    <tinycom
      id="tiny_com"
      @click="emitEvent"
      :called-by="tiny_calledby"
      :operation="tiny_operation"
      hidden
    ></tinycom>
  </div>
</template>
<script>
import Vue from 'vue';
import VueEvents from 'vue-events';
Vue.use(VueEvents);
export default {
  name: 'tiny-editor',
  props: {
    id: String,
    init: {
      type: Object,
      default: JSON.parse('{"height": "400"}'),
    },
    htmlContent: {
      type: String,
      default: '',
    },
  },
  components: {
    editor: () =>
      import(/* webpackChunkName: "tinymce" */ '@tinymce/tinymce-vue'),
  },
  computed: {
    tinyeditor: {
      get: function() {
        return this.value;
      },
      set: function(val) {
        this.$emit('input', val);
      },
    },
  },
  data() {
    return {
      //tinySrc: '/tinymce/js/tinymce/tinymce.min.js',
      content: this.value,
      editor: true,
      tiny_calledby: 'trash',
      tiny_operation: '',
    };
  },
  created() {
    //console.log(this.config_tiny);
    // Select the node that will be observed for mutations
  },
  mounted() {
    let modal = document.getElementById('tinyMocal');
    let btnclose = document.getElementById('close-color-selector');
    let btncancelar = document.getElementById('cancel-s-color');
    // When the user clicks on <span> (x), close the modal
    btncancelar.onclick = function() {
      modal.style.display = 'none';
    };
    btnclose.onclick = function() {
      modal.style.display = 'none';
    };
  },
  methods: {
    emitEvent: function() {
      //let tinycom = document.getElementById('tiny_com');
      console.log('llamado emisor de eventos');
      let tiny_com = document.getElementById('tiny_com');
      this.tiny_calledby = tiny_com.getAttribute('called-by');
      this.tiny_operation = tiny_com.getAttribute('operation');
      this.$events.$emit('tiny-op', this.tiny_calledby, this.tiny_operation);
    },
    tinyloaded: function() {
      let tinyContainer = window.tinymce.editors[this.id];
      tinyContainer.setContent(this.htmlContent, {format: 'raw'});
    },
  },
};
</script>
