export {_tiny_config_default};
const comm_button = 'tiny_com';
const _tiny_config_default = {
  //selector: '#editor',
  //height: 400,
  content_css: './duplicated.css',
  relative_urls: false,
  paste_data_images: true,
  selector: '#editor',
  height: 400,
  language: 'es',
  valid_elements: '*[*]',
  extended_valid_elements:
    'i[class],span[class|style|font-weight|content|font-size|background-color|text-decoration-line|text-align],div[class|style|href|data-toggle|role|aria-expanded|aria-controls|id],h3[class],p[class],br',
  verify_html: false,
  draggable_modal: true,
  formats: {
    'greycolor': {color: '#ced4d9'},
  },
  //table_responsive_width: false,
  //toolbar_mode: 'sliding',
  //inline: true,
  //fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
  table_default_attributes: {
    border: '0',
  },
  table_default_styles: {
    width: '90%',
  },
  //menubar: 'table',
  menubar: false,
  //toolbar_location: 'bottom',
  plugins: 'table image charmap codesample',
  table_header_type: 'section',
  content_style:
    'body {  font-size: 20px; font-family: Avenir, Helvetica, Arial, sans-serif; } .border-tiny{border-style:outset!important;} .separacion20px{padding-left:20px;}\
         .bolder{font-weight: 900;font-size: 23px;text-align: center;vertical-align: baseline;}\
         /*distintos tipos de flechas*/\
span.flecha-arpon-doble:before{\
	content: "\\21cc";\
}\
span.flecha-arpon-doble-invertida:before{\
	content: "\\21cb";\
}\
span.flecha-doble:before{\
	content: "\\21c4";\
}\
span.flecha-doble-invertida:before{\
	content: "\\21c6";\
}\
span.flecha-a-derecha:before{\
	content: "\\2192";\
}\
span.flecha-a-izquierda:before{\
	content: "\\2190";\
}\
span.flecha-hacia-abajo:before{\
	content: "\\2193\
}\
span.punto-multiplicación:before{\
    content: "\\2219";\
    font-weight: bolder;\
}\
span.m-bolder:before{\
    content: "\\004D";\
    font-weight: bolder;\
}	\
span.elemento-checked:before{\
    content: "\\2714";\
    font-weight: bolder;\
}\
span.elemento-wrong:before{\
    content: "\\2716";\
    font-weight: bolder;\
}\
span.lineabaja:before{\
    text-decoration-line: underline;\
    content:"__________";\
}\
.marcado-oculto{\
    background-color:rgb(255, 255, 155);\
}\
span.completar-texto:before{\
    text-decoration-line: underline;\
    content:"__________";\
}\
/*Tamaños de fuentes de texto*/\
span.texto10px{ \
    font-size: 10px;\
}\
span.texto15px{\
    font-size: 15px;\
}\
span.texto25px{\
    font-size: 25px;\
}\
span.texto20px{\
    font-size:20px;\
}\
span.texto30px{\
    font-size:30px;\
}\
span.texto40px{\
    font-size: 40px;\
}\
span.texto50px{\
    font-size: 50px;\
}\
/*Resaltado de texto*/ \
span.resaltador-cyan{\
    background-color: cyan;\
}\
span.resaltador-salmon{\
    background-color: salmon\
}\
span.resaltador-amarilloclaro{\
    background-color: lightyellow;\
}\
span.resaltador-amarillo{\
    background-color:rgb(255, 255, 155);\
}\
span.resaltador-naranja{\
    background-color: rgb(235, 200, 134);\
}\
span.resaltador-verde{\
    background-color: rgb(76, 221, 124);\
}\
span.resaltador-rojo{\
    background-color:red;\
}\
span.resaltador-celeste{\
    background-color: lightblue;\
}\
.resaltado-temporal{\
    background-color:white;\
}\
/*como se muestra el texto*/\
span.bolder{\
    font-weight: 900;\
    font-size: 23px;\
    text-align: center;\
    vertical-align: baseline;\
}\
span.textojustificado{\
    text-align: justify;\
    text-justify: inter-word;\
    margin-left:10px;\
    margin-right:10px;\
  }\
span.textocentrado{\
    text-align: center;\
}\
\
span.textocursiva{\
    font-style: italic;\
}\
span.textonegrita{\
    font-weight: bold;\
}\
span.textosubrayado{\
    text-decoration: underline;\
}\
span.margenextra10px{\
    margin-left:10px;\
    margin-right:10px;\
}\
span.margenextra15px{\
    margin-left:15px;\
    margin-right:15px;\
}\
span.margenextra20px{\
    margin-left:20px;\
    margin-right:20px;\
}\
span.margenextra30px{\
    margin-left:30px;\
    margin-right:30px;\
}\
span.margenextra20pxderecho{\
    margin-left:20px;\
}\
span.separacion20px{\
    padding-left:20px;\
}\
span.separacion20pxderecha{\
    padding-right:20px;\
}\
span.alineacionderecha{\
    text-align:right;\
}\
span.separacion20px:before{\
	padding-left:20px;\
}\
.table{\
    margin-right:30px!important;\
}\
\
/*Regla de tres*/ \
table.sinborde{\
    border-style: none;\
}\
span.textoalineadoderecha{\
    text-align:right!important;\
}\
span.ancholineabaja{\
    width: 15px;\
}\
span.colorElemento1 {\
    color: #1ab188;\
  }\
\
.imagenCentrada{\
    display: block;\
    margin-left: auto;\
    margin-right: auto;\
    width: 60%;\
}\
@media only screen and (max-width: 500px) {\
    .imagenCentrada{\
        display: block;\
        margin-left: auto;\
        margin-right: auto;\
        width: 100%;\
    }\
  }\
  \
  @media only screen and (max-width: 768px) {\
    .imagenCentrada{\
        display: block;\
        margin-left: auto;\
        margin-right: auto;\
        width: 85%;\
    }\
  }\
  \
  /* Large devices (laptops/desktops, 992px and up) */\
  @media only screen and (max-width: 992px) {\
    .imagenCentrada{\
        display: block;\
        margin-left: auto;\
        margin-right: auto;\
        width: 80%;\
    }\
  }\
\
  /* TINY MCE */\
  \
  .tox-notification{\
      display: none !important;\
  }\
  .tox-statusbar__branding{\
    display: none !important;\
  }\
  /*\
  .tox-menu {\
    position: fixed;\
    width: 500px;\
    height: 200px;\
    top: 50%;\
    left: 50%;\
    margin-top: -100px; \
 }*/',
  // eslint-disable-next-line no-unused-vars
  menu: {
    concepto: {
      title: 'Concepto',
      items: 'ocultarInfoConcepto',
    },
    herramientas: {
      title: 'Herramientas',
      items: 'agregar-seccion resaltar-fondo rawhtml',
    },
    previsualizar: {
      title: 'Vista previa como',
      items: 'prevComoConcepto prevComoCompletar prevComoTarjeta',
    },
  },
  // eslint-disable-next-line
  toolbar2: 'tableinsertdialog tableprops  tabledelete | tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
  toolbar1:
    'guardar | undo redo | formatselect codesample | bold italic underline overlined  forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | superscript | subscript| doble-flecha | flecha-a-derecha | flecha-hacia-abajo-a-esquina-derecha |\
                    flecha-hacia-abajo | flecha-hacia-arriba | punto-multiplicacion | noteq | approx | approx2 | masmenos | sqrt | molar | delta | image | charmap | separacion | agregar-seccion | prevNormal | codesample',
  images_dataimg_filter: function(img) {
    return img.hasAttribute('internal-blob');
  },
  setup: function(editor) {
    editor.ui.registry.addButton('delta', {
      text: '&Delta;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span>&Delta;</span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('punto-multiplicacion', {
      text: '&#8729;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span class="bolder">&#8729;</span> '
          );
        }
      },
    });

    editor.ui.registry.addButton('overlined', {
      text: '&#8950;',
      tooltip: 'línea sobre el texto',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection.getContent().length) {
          editor.selection.setContent(
            '<span style="text-decoration: overline;">' +
              editor.selection.getContent() +
              '</span>'
          );
        }
      },
    });
    editor.ui.registry.addButton('sqrt', {
      text: '&#8730;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span> &#8730; </span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('molar', {
      text: '&#653;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span> &#653; </span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('noteq', {
      text: '&#8800;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span> &#8800; </span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('approx2', {
      text: '&#8773;',
      tooltip: 'aproximadamente igual a',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span> &#8773; </span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('masmenos', {
      text: '&#177;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span> &#177; </span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('approx', {
      text: '&#8776;',
      tooltip: 'casi igual a',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span> &#8776; </span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('separacion', {
      text: '&#8677;',
      tooltip: 'separación en esta línea',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            true,
            '<span class="separacion20px"></span>'
          );
        }
      },
    });
    editor.ui.registry.addButton('flecha-hacia-abajo-a-esquina-derecha', {
      text: '&#8627;',
      onAction: function() {
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span class="bolder">&#8627;</span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('flecha-hacia-arriba', {
      text: '&#8593;',
      onAction: function() {
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span class="bolder">&#8593;</span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('flecha-hacia-abajo', {
      text: '&#8595;',
      onAction: function() {
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span class="bolder">&#8595;</span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('flecha-a-derecha', {
      text: '&#10145;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span class="bolder">&#10145;</span> '
          );
        }
      },
    });
    editor.ui.registry.addButton('doble-flecha', {
      text: '&#8644;',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection) {
          //console.log('pero no inserta nada');
          editor.execCommand(
            'mceInsertContent',
            false,
            '<span class="bolder">&#8644;</span> '
          );
          //editor.execCommand('mceInsertRawHTML', false, '<span class="flecha-doble"></span>');
          //editor.setContent('<span class="flecha-doble"></span>', {format: 'raw'})
        }
      },
    });
    editor.ui.registry.addButton('agregar-seccion', {
      text: 'Agregar seccion colapsable',
      tooltip: '',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        let seed = Date.now();
        let colapsable =
          ' <div class="panel-title panel-heading accordion-toggle border-tiny"  data-toggle="collapse" href="#colapsarTexto' +
          seed +
          '" role="button" aria-expanded="false" aria-controls="colapsarTexto' +
          seed +
          '"><h3 class="textonegrita" >TITULO</h3></div><div class="collapse claseextra border-tiny" id="colapsarTexto' +
          seed +
          '"><div class="card card-body"><p class="margenextra15px"><span></span></p></div></div> ';
        editor.execCommand('mceInsertContent', false, colapsable);
      },
    });
    editor.ui.registry.addButton('ocultarInfoConcepto', {
      text: 'Confirmar selección para ocultar',
      tooltip:
        'Los conceptos pueden ser cargados como frases para \
  completar. Para esto primero tenés \
  que seleccionar en el texto la/s parte/s a ocultar,\
  y luego presioanr este botón.',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        if (editor.selection.getContent().length) {
          editor.selection.setContent(
            '<mark class="resaltado-temporal"><span class="marcado-oculto">' +
              editor.selection.getContent() +
              '</span></mark>'
          );
        }
      },
    });
    editor.ui.registry.addButton('resaltar-fondo', {
      text: 'Setear fondo tabla',
      tooltip:
        'Selecciona la fila, y luego usa esta opción, para resaltar tu encabezado.',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        editor.focus();
        //console.log(editor.selection.getNode().parentNode.parentNode);
        let modal = document.getElementById('tinyMocal');
        modal.style.display = 'block';
        let btnconfirmar = document.getElementById('select-color');

        // When the user clicks the button, open the modal
        btnconfirmar.onclick = function() {
          //console.log(document.getElementById('selector-de-color').value);
          if (
            editor.selection.getNode().parentNode.parentNode.tagName === 'TR'
          ) {
            editor.selection.getNode().parentNode.parentNode.style =
              'background-color:' +
              document.getElementById('selector-de-color').value +
              ';';
          }
          modal.style.display = 'none';
          //return document.getElementById('selector-de-color').value;
        };
        //document.getElementById('colorpicker').click();
      },
    });
    editor.ui.registry.addButton('prevNormal', {
      text: 'Previsualizar contenido',
      tooltip: '',
      onAction: function() {
        emitEvent(editor.id, 'previsualizar');
      },
    });
    editor.ui.registry.addButton('guardar', {
      text: '&#128190;',
      tooltip: 'guarda el contenido',
      onAction: function() {
        emitEvent(editor.id, 'guardar');
      },
    });
    editor.ui.registry.addButton('prevComoConcepto', {
      text: 'Concepto',
      tooltip: '',
      onAction: function() {
        emitEvent(editor.id, 'previsualizar-concepto');
      },
    });
    editor.ui.registry.addButton('prevComoCompletar', {
      text: 'Concepto para completar',
      tooltip: '',
      onAction: function() {
        emitEvent(editor.id, 'previsualizar-completar');
      },
    });
    editor.ui.registry.addButton('prevComoTarjeta', {
      text: 'Tarjeta',
      tooltip: '',
      onAction: function() {
        emitEvent(editor.id, 'previsualizar-tarjeta');
      },
    });
    editor.ui.registry.addButton('rawhtml', {
      text: 'Interpretar como html',
      tooltip: 'Make it raw',
      onAction: function() {
        //let _editor = window.tinymce.editors['contenido-completo'];
        console.log('llamado para setear contenido');
        editor.setContent('', {format: 'raw'});
      },
    });
  },
};
function emitEvent(idEditor, operacion) {
  let tiny_com = document.getElementById(comm_button);
  tiny_com.setAttribute('called-by', idEditor);
  tiny_com.setAttribute('operation', operacion);
  tiny_com.click();
}
